<template>
    <div class="btn-group">
        <button
            type="button"
            class="btn waves-effect waves-light d-flex flex-column px-1"
            @click="btnCallback"
        >
            <i :class="`ti ti-${icon} fs-4 p-0 pb-1`"></i>
            <span class="first-letter-capitalize">{{ $t(label) }}</span>
        </button>

        <!-- Collapse trigger -->
        <button
            v-if="!hideCollapse"
            type="button"
            class="btn dropdown-toggle dropdown-toggle-split waves-effect waves-light fs-5 fw-bold"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
        >
            <span class="visually-hidden">Toggle Dropdown</span>
        </button>

        <ul class="dropdown-menu dropdown-menu-end">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Dropdown",
    props: ["icon", "label", "hideCollapse", "avoidAutoClose", "btnCallback"]
}
</script>

<style scoped>
    .dropdown-menu li {
        color: black;
    }

    .custom-danger-btn > button.btn {
        color: #ea5455;
    }
</style>
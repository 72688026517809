<template>

    <div class="d-flex justify-content-end align-items-center">

        <button v-if="component_mode == 'create'" class="btn btn-primary m-2" @click="addEvent()">
            <span v-if="!isLoading">{{$t("save")}}</span>
            <span v-if="isLoading">
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </span>
        </button>
        <button v-if="component_mode == 'edit'" class="btn btn-primary m-2" @click="editEvent()">
            <span v-if="!isLoading">{{ $t("save") }}</span>
            <span v-if="isLoading">
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </span>
        </button>
    </div>
    <veeForm ref="dataForm" as="div" name="dataForm" v-slot="{ errors }">

        <div class="row pe-2 ps-2">

            <div class="col-md-6">
                <div class="card p-3">
                    <h4>{{$t("event_details")}}</h4>


                    <div class="mb-3 form-group">
                        <label>{{$t("title")}}:</label> <span class="text-danger">*</span>
                        <!-- <input v-model="formData.name" type="text" class="form-control"> -->
                        <Field type="text" v-model="formData.name" name="title" label="Title" class="form-control" rules="required">
                        </Field>
                        <div class="text-danger">{{ errors.title }}</div>
                    </div>

                    <!-- <div class="mb-3 form-group">
                        <label class="mb-2">{{$t("event_type")}}:</label>
                        <br>
                        <input v-model="formData.is_public" :value="true" type="radio" name="meeting_type"
                            class="form-check-input" checked> <label class="form-check-label"> {{ $t("allow_join_without_host_consent") }}</label> <span class="text-primary" data-bs-toggle="tooltip"
                            data-bs-placement="right" :title="$t('anybody_can_join')">(?)</span>
                        <br>
                        <input v-model="formData.is_public" :value="false" type="radio" name="meeting_type"
                            class="form-check-input mt-2"> <label class="form-check-label mt-2">{{ $t("allow_join_only_upon_host") }}</label> <span class="text-primary" data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            :title="$t('host_needs_to_admit')">(?)</span>
                    </div> -->

                    <div class="mb-3 form-group">
                        <label>{{$t('select_timezone')}}:</label> <span class="text-danger">*</span>
                        <!-- <select class="form-select">
                            <option selected>America/New_York</option>
                            <option>Asia/Kolkata</option>
                        </select> -->
                        <v-select v-model="formData.timezone" label="timezone_with_offset"
                            :reduce="option => option.timezone" :options="timezones" :clearable="false"></v-select>
                    </div>

                    <div class="mb-3 form-group">
                        <label class="mb-1 d-none">{{ $t('event_timing') }}:</label>

                        <!-- <div class="row">
                            <div class="col-md-6">

                                <label>{{ $t("start_date") }}:</label> <span class="text-danger">*</span>
                                <div>
                                    <VueDatePicker v-model="date_time_selection.startDate" :week-start="0" :format="formatDate" :enableTimePicker="false"
                                        auto-apply :clearable="false" />
                                </div>
                                <br>
                                <label> {{ $t("start_time") }}:</label> <span class="text-danger">*</span>
                               
                                <div>
                                    <VueDatePicker v-model="date_time_selection.startTime" time-picker :is-24="false" auto-apply :clearable="false" />

                                </div>


                            </div>
                            <div class="col-md-6">

                                <label>{{ $t('end_date') }}:</label> <span class="text-danger">*</span>
                              
                                <div>
                                    <VueDatePicker v-model="date_time_selection.endDate" :week-start="0" :format="formatDate" :enableTimePicker="false"
                                        auto-apply :clearable="false" />
                                </div>
                                <br>
                                <label>{{$t('end_time')}}:</label> <span class="text-danger">*</span>
                              
                                <div>
                                    <VueDatePicker v-model="date_time_selection.endTime" time-picker :is-24="false" auto-apply :clearable="false" />

                                </div>
                            </div>
                        </div> -->

                        <div class="row mb-3">
                            <div class="col-xl-4">
                                <label>{{ $t("start_date") }}:</label> <span class="text-danger">*</span>
                                <div>
                                    <VueDatePicker v-model="date_time_selection.startDate" :week-start="0" :format="formatDate" :enableTimePicker="false"
                                        auto-apply :clearable="false" />
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <label> {{ $t("start_time") }}:</label> <span class="text-danger">*</span>
                                <div>
                                    <VueDatePicker v-model="date_time_selection.startTime" time-picker :is-24="false" auto-apply :clearable="false" />

                                </div>
                            </div>

                            <div class="col-xl-4">
                                <!-- durations : [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180] -->
                                <label> {{ $t("duration") }}:</label> <span class="text-danger">*</span>
                                <select v-model="selected_duration" class="form-select">
                                    <option value="15">15 {{$t("minutes")}}</option>
                                    <option value="30">30 {{$t("minutes")}}</option>
                                    <option value="45">45 {{$t("minutes")}}</option>
                                    <option value="60">1 {{$t("hour")}}</option>
                                    <option value="75">1 {{$t("hour")}} 15 {{$t("minutes")}}</option>
                                    <option value="90">1 {{$t("hour")}} 30 {{$t("minutes")}}</option>
                                    <option value="105">1 {{$t("hour")}} 45 {{$t("minutes")}}</option>
                                    <option value="120">2 {{$t("hours")}}</option>
                                    <option value="135">2 {{$t("hours")}} 15 {{$t("minutes")}}</option>
                                    <option value="150">2 {{$t("hours")}} 30 {{$t("minutes")}}</option>
                                    <option value="165">2 {{$t("hours")}} 45 {{$t("minutes")}}</option>
                                    <option value="180">3 {{$t("hours")}}</option>
                                </select>
                            </div>

                        </div>



                    </div>

                    <div class="mb-3 form-group">
                        <label>{{$t('description')}}:</label>
                        <textarea v-model="formData.description" class="form-control" rows="4"></textarea>
                        <Field type="hidden" v-model="formData.description" name="description" class="form-control" >
                        </Field>
                        <div class="text-danger">{{ errors.description }}</div>
                    </div>


                </div>
            </div>

            <div class="col-md-6">
                <div class="card p-3">
                    <h4>{{$t('participants')}}</h4>

                    <!-- Search Contacts -->
                    <div class="search-component mb-1">
                        <input type="text" ref="search_inp" v-model="searchTerm" placeholder="Search..."
                            @input="fetchItemsDebounced" class="form-control" />
                        <ul v-if="items.length > 0 && searchTerm.trim() !== ''" class="dropdown-menu mt-1 shadow show">
                            <li v-for="item in items" :key="item.id" class="dropdown-item" @click="addToSelected(item)">
                                {{ item.full_name }} | {{ item.email }} {{ item.sub_type?.name ? '('+item.sub_type?.name+')' : "" }}
                            </li>
                        </ul>
                    </div>

                    <div class="text-primary cursor-pointer mb-3" data-bs-toggle="modal"
                        data-bs-target="#addContactModal">{{ $t('not_listed_add_new_select') }}</div>


                    <!-- Display Contacts -->
                    <div class="text-center fw-bold mt-2" v-if="!selectedItems.length"> {{ $t("no_contacts_selected") }}</div>
                    <div v-if="selectedItems.length > 0">
                        <div class="w-100 border mt-2">


                            <div v-for="(item,index) in selectedItems" class="d-flex justify-content-between p-1 m-2">
                                <!-- contact - item -->
                                <div>{{ item.full_name }} | {{ item.email }} {{ item.sub_type?.name ? '('+item.sub_type?.name+')' : "" }}</div>
                                <div>
                                    <i class="fa fa-remove" @click="removeFromSelected(item.id)"></i>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>


        </div>
    </veeForm>


    <!-- Add Contact Modal -->
    <div class="modal fade" id="addContactModal" tabindex="-1" aria-labelledby="addContactModal" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <form @submit.prevent="addContactAndSelect()">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t("add_new_contact_select") }}</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 form-group">
                            <label>{{ $t('first_name') }}:</label> <span class="text-danger">*</span>
                            <input ref="formInput1" v-model="modalData.first_name" type="text" class="form-control" required>
                        </div>

                        <div class="mb-3 form-group">
                            <label>{{$t('last_name')}}:</label> <span class="text-danger">*</span>
                            <input v-model="modalData.last_name" type="text" class="form-control" required>
                        </div>

                        <div class="mb-3 form-group">
                            <label>{{$t("email")}}: </label> <span class="text-danger">*</span>
                            <input v-model="modalData.email" type="email" class="form-control" required>
                        </div>

                        <div class="mb-3 form-group">
                            <label for="name">{{ $t("type") }}</label> <span class="text-danger">*</span>
                            <select class="form-select" v-model="modalData.sub_type">
                                <option v-for="item,index in shared.contact_sub_types" :key="index" :value="item.id" >
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>


                        <div class="mb-3 form-group">
                            <label for="name">{{ $t("language") }}</label> <span class="text-danger">*</span>
                            <select class="form-select" v-model="modalData.language_code">
                                <option v-for="item,index in $langStore.languages" :value="item.locale">
                                    {{ item.language }}
                                </option>
                            </select>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">{{$t("add_select")}}</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("cancel") }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Form, Field } from 'vee-validate';
import { getTimezonesWithOffset } from '@/js/timezones';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import moment from "moment-timezone";
import Swal from "sweetalert2";
import { debounce } from 'lodash';

export default {
    props: ["shared"],
    data() {
      return {
        searchTerm: '',
        items: [],
        selectedItems: [],
        timezones : getTimezonesWithOffset(),
        date_time_selection : {
            startDate : "",
            startTime : {hours : "15", minutes :15},
            endDate : "",
            endTime:  {hours : "16", minutes : 20}
        },
        formData : {
            name : "",
            description : "",
            host : this.$commonStore.state.user.id,
            participants : [],
            is_public : true,
            start_date_time: "",
            end_date_time: "",
            timezone : moment.tz.guess()
        },

        modalData : {
            first_name: "",
            last_name: "",
            email: "",
            language_code: "en",
            sub_type : ""
        },

        component_mode : "create",
        isLoading : false,
        selected_duration : "15"

      };
    },
    components: {
        'VeeForm': Form,
        Field,
        vSelect,
        VueDatePicker,
    },
    watch : {
        "date_time_selection" : {
            handler(newVal, oldVal) {

                let selected_tz = this.formData.timezone;

                // component's result from intergers to string 
                let startHours = String(newVal.startTime.hours).padStart(2, '0');
                let startMinutes = String(newVal.startTime.minutes).padStart(2, '0');
                let endHours = String(newVal.endTime.hours).padStart(2, '0');
                let endMinutes = String(newVal.endTime.minutes).padStart(2, '0');

                

                // String with Datetime
                let startDateTimeStr = `${moment(newVal.startDate).format('YYYY-MM-DD')} ${startHours}:${startMinutes}:00`;
                //let endDateTimeStr = `${moment(newVal.endDate).format('YYYY-MM-DD')} ${endHours}:${endMinutes}:00`;
                let endDateTimeStr = moment(startDateTimeStr).clone().add(this.selected_duration, "minutes").format("YYYY-MM-DD HH:mm");


                let startDateTime = moment.tz(startDateTimeStr, selected_tz);
                let endDateTime = moment.tz(endDateTimeStr, selected_tz);


                // convert to UTC.
                this.formData.start_date_time = startDateTime.utc().format("YYYY-MM-DD HH:mm");
                this.formData.end_date_time = endDateTime.utc().format("YYYY-MM-DD HH:mm");
            },
            deep : true
        },
        "formData.timezone" : function(newVal, oldVal) {

                let selected_tz = newVal;


                // component's result from intergers to string 
                let startHours = String(this.date_time_selection.startTime.hours).padStart(2, '0');
                let startMinutes = String(this.date_time_selection.startTime.minutes).padStart(2, '0');
                let endHours = String(this.date_time_selection.endTime.hours).padStart(2, '0');
                let endMinutes = String(this.date_time_selection.endTime.minutes).padStart(2, '0');

                // String with Datetime
                let startDateTimeStr = `${this.date_time_selection.startDate} ${startHours}:${startMinutes}:00`;
                //let endDateTimeStr = `${this.date_time_selection.endDate} ${endHours}:${endMinutes}:00`;
                let endDateTimeStr = moment(startDateTimeStr).clone().add(this.selected_duration, "minutes").format("YYYY-MM-DD HH:mm");

                // this.date_time_selection 's endDate and endTime need to be updated here if visible.

                let startDateTime = moment.tz(startDateTimeStr, selected_tz);
                let endDateTime = moment.tz(endDateTimeStr, selected_tz);


                // convert to UTC.
                this.formData.start_date_time = startDateTime.utc().format("YYYY-MM-DD HH:mm");
                this.formData.end_date_time = endDateTime.utc().format("YYYY-MM-DD HH:mm");
        },

        "selected_duration" : function (newVal, oldVal) {

            let startDateTime = moment(this.formData.start_date_time);

            let endDateTimeTemp = startDateTime.clone().add(newVal, "minutes").format("YYYY-MM-DD HH:mm");

            this.formData.end_date_time = endDateTimeTemp;

            // this.date_time_selection 's endDate and endTime need to be updated here if visible.

        }

    },
    methods: {
        async isValid() {
            const form = this.$refs.dataForm;
            const isValid = await form.validate();
            if (isValid.valid == false) {
                alert("there is error")
            }
            else {
                alert("valid From");
            }
        },

        fetchItemsDebounced : debounce(function () {
            this.fetchItems();
        }, 300),

        fetchItems() {
           let self=this;
           let url = "user/contacts" + "?keyword=" + self.searchTerm;
            this.$axios.get(url).then(response => {
                if(response.status == 200) {
                    self.items = response.data.data.contacts; 
                }
            })
            
      },
      addToSelected(item) {

        // if(this.selectedItems.some(el => el.id = item.id))
        // {
        //     toastr.error("Praticipant already present" ,"Error" , {
        //                 closeButton: true,
        //                 tapToDismiss: false,
        //                 timeOut: 3000,
        //                 rtl: false,
        //                 });
        //     return;
        // }
        let foundFlag = false;
        this.selectedItems.forEach(el => {
            if (el.id == item.id) {
            foundFlag = true;
            this.searchTerm = '';
            }
        })
        
        if(foundFlag) {
            toastr.error(this.$t("participant_already_present") ,this.$t("error") , {
                    closeButton: true,
                    tapToDismiss: false,
                    timeOut: 3000,
                    rtl: false,
                    });
            return
        };
        this.selectedItems.push(item);
        // Clear search term after selection
        this.searchTerm = '';
        this.$refs.search_inp.focus()
      },

      removeFromSelected(id) {
        this.selectedItems = this.selectedItems.filter(item => item.id != id);
      },

      setDefaultDateTime(custom) {

        // Set custom dateTime from selected_event in edit mode
        if(custom) {
            let startTime = this.shared.selected_event.start_date_time;
            let endTime = this.shared.selected_event.end_date_time;
            let timezone = this.shared.selected_event.timezone;

            //convert to the specified timezone and format the date
            this.date_time_selection.startDate = moment.tz(startTime, timezone).format("YYYY-MM-DD");
            this.date_time_selection.endDate = moment.tz(endTime, timezone).format("YYYY-MM-DD");

            // extract hours and minutes for start time
            this.date_time_selection.startTime = {
                hours: moment.tz(startTime, timezone).format("HH"),
                minutes: moment.tz(startTime, timezone).format("mm")
            }

            // Extract hours and minutes for end time
            this.date_time_selection.endTime = {
                hours: moment.tz(endTime, timezone).format("HH"),
                minutes: moment.tz(endTime, timezone).format("mm")
            }

            let duration = moment(endTime).diff(moment(startTime), "minutes");
            
            this.selected_duration = String(duration)

            return;
        }

        // set a default Date time in create mode
        // addes 1 hour from current time in endTime
        let date = moment().format("YYYY-MM-DD");
        let startTime = moment().format("HH:mm");
        let endTime = moment().add(this.selected_duration,"minutes").format("HH:mm");

        this.date_time_selection.startDate = date;
        this.date_time_selection.endDate = date;

        this.date_time_selection.startTime = {hours : startTime.split(":")[0], minutes : startTime.split(":")[1]};
        this.date_time_selection.endTime = {hours : endTime.split(":")[0], minutes : endTime.split(":")[1]};

      },
      async addEvent() {
        let self = this;
        let url = "event";

        const form = this.$refs.dataForm;
        const isValid = await form.validate();
        if (!isValid.valid) {
            toastr.error(this.$t("required_items_missing"), this.$t("something_went_wrong"),  {
                closeButton: true,
                tapToDismiss: false,
                timeOut: 5000,
                rtl: false,
                });
            return;
        }

        self.formData.participants = self.selectedItems.map((item,index) => {
            return item.id;
        });

        if(!self.validateTime(self.formData.start_date_time, self.formData.end_date_time)) {
            toastr.error(this.$t("end_time_greater_than_start"), this.$t("something_went_wrong"),  {
                closeButton: true,
                tapToDismiss: false,
                timeOut: 3000,
                rtl: false,
                });
            return;
        };
        

        self.isLoading = true;
        self.$axios.post(url,self.formData).then(response => {
            if(response.status == 200) {
            self.shared.events.unshift(response.data.data);
            toastr.success(this.$t("event_schedual_success"), this.$t("success"),{
                    closeButton: true,
                    tapToDismiss: false,
                    timeOut: 5000,
                    rtl: false,
                    })
            
            self.$router.push({"name" : "events-list"});

            }
        self.isLoading = false;

        }).catch(e => {
            toastr.error(e.response.data.message, this.$t("something_went_wrong"),  {
                        closeButton: true,
                        tapToDismiss: false,
                        timeOut: 6000,
                        rtl: false,
                        });
            self.isLoading = false
        }); 
      },
      async editEvent() {
        let self = this;
        let id = self.shared.selected_event.id
        let url = "event/"+ id;

        const form = this.$refs.dataForm;
        const isValid = await form.validate();
        if (!isValid.valid) {
            toastr.error(this.$t("required_items_missing"), this.$t("something_went_wrong"),  {
                closeButton: true,
                tapToDismiss: false,
                timeOut: 5000,
                rtl: false,
                });
            return;
        }

        if(!self.validateTime(self.formData.start_date_time, self.formData.end_date_time)) {
            toastr.error(this.$t("end_time_greater_than_start"), this.$t("something_went_wrong"),  {
                closeButton: true,
                tapToDismiss: false,
                timeOut: 3000,
                rtl: false,
                });
            return;
        };

        
        self.formData.participants = self.selectedItems.map(item => item.id);
        self.isLoading = true
        self.$axios.put(url, self.formData).then(response => {
            if(response.status == 200) {

                toastr.success(this.$t("event_update_success"), this.$t("success") , {
                        closeButton: true,
                        tapToDismiss: false,
                        timeOut: 5000,
                        rtl: false,
                        })
                this.editEventInState(response.data.data);
                self.$router.push({"name" : "events-list"});
            }
            self.isLoading = false
        }).catch(e => {
            toastr.error(e.response.data.message, this.$t("something_went_wrong") , {
                        closeButton: true,
                        tapToDismiss: false,
                        timeOut: 6000,
                        rtl: false,
                        });
            self.isLoading = false
        })
      },
        addContactAndSelect() {
            let self = this;
            let url = "user/contact";


            this.$axios.post(url, self.modalData).then(response => {
                if (response.status == 200) {
                    toastr.success(this.$t("contact_added_succcess"), this.$t("success"), {
                        closeButton: true,
                        tapToDismiss: false,
                        timeOut: 2000,
                        rtl: false,
                    })
                    self.selectedItems.push(response.data.data);
                    self.modalData.first_name = ""
                    self.modalData.last_name = ""
                    self.modalData.email = ""

                    //close modal if success
                    var myModalEl = document.getElementById('addContactModal');
                    var modal = bootstrap.Modal.getInstance(myModalEl)
                    modal.hide();

                    // // hide modal if success
                    // const myModal = new bootstrap.Modal(document.getElementById('addContactModal'));
                    // console.log("bs modal instance", myModal);
                    // myModal.hide(); // hide modal
                }
                self.isLoading = false;
            }).catch(e => {
                toastr.error(e.response.data.message, this.$t("something_went_wrong"), {
                    closeButton: true,
                    tapToDismiss: false,
                    timeOut: 3000,
                    rtl: false,
                });
                self.isLoading = false;
            })
      },
      formatDate(date) {
        return moment(date).format("MMM DD YYYY");
      },
    //   closeModal() {
    //     var myModalEl = document.getElementById('addContactModal');
    //     var modal = bootstrap.Modal.getInstance(myModalEl)
    //     modal.hide();
    //   },
      validateTime(start_date_time, end_date_time) {
        var start = moment(start_date_time);
        var end = moment(end_date_time);

        if (end.isAfter(start)) {
            return true;
        } else {
            return false;
        }

      },
      cloneObj(item) {
        return JSON.parse(JSON.stringify(item));
      },

    editEventInState(newEvent) {

        let id = newEvent.id;

        for(let i=0; i< this.shared.events.length; i++) {
            if(this.shared.events[i].id == id ) {
                this.shared.events[i].name = newEvent.name;
                this.shared.events[i].full_name = newEvent.full_name;
                this.shared.events[i].start_date_time = newEvent.start_date_time;
                this.shared.events[i].end_date_time = newEvent.end_date_time;
                this.shared.events[i].participants = newEvent.participants;
                this.shared.events[i].is_public = newEvent.is_public;
                this.shared.events[i].description = newEvent.description;
                this.shared.events[i].timezone = newEvent.timezone;

            }
        }
    },
    addEventInState(newEvent) {
        this.shared.events.unshift(newEvent);
    }
    },
    created() {

        // check permisson
        if(!this.$commonStore.hasPermission('events','view_manage')) {
            this.$router.push({'name' : 'app'})
        }

        if (this.$route.name == "events-edit") {
            this.component_mode = "edit"
        }

        if (this.component_mode == "edit") {
            if (!this.shared.selected_event.participants) {
                // if browser refreshed in edit mode than shared.selected_event will not be present.
                // then redirect to list.
                this.$router.push({ 'name': "events-list" })
                return;
            }
            this.formData = this.cloneObj(this.shared.selected_event);
            this.selectedItems = this.cloneObj(this.shared.selected_event).participants;
            this.setDefaultDateTime(true);
        }
        else {
            this.setDefaultDateTime();
        }
    },

    mounted() {
        try {
            setTimeout(() => {
            this.modalData.sub_type = this.shared.contact_sub_types[2]?.id ?? this.shared.contact_sub_types[0]?.id ;
            },1500);
        }
        catch(e) {

        }
    }
}
</script>
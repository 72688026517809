<template>
    <div :class="{
        'd-flex align-items-center gap-2' : displayType == 'inline',
        'mb-2' : displayType == 'form',
    }">
        <label class="text-nowrap" :class="{'text-white' : displayType == 'inline'}">{{$t('spoken_language')}} <span :title="$t('this_helps_improve_trascription_description')" data-bs-toggle="tooltip" data-bs-title="{{$t('this_helps_improve_trascription_description')}}">(?)</span> </label>
        <select v-model="selected_spoken_language" class="form-select" :class="[ displayType == 'inline' ? 'form-select-sm' : '']" @input="updateSpokenLanguage">
            <option value="en">English</option>
            <option value="es">Spanish</option>
            <option value="en_es">English/Spanish Mix</option>
        </select>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useCallStore } from '@/stores/call';
export default {
    props : [
        "participant",
        "displayType" // form or inline
    ],
    data() {
        return {
            selected_spoken_language : 'en'
        }
    },
    computed : {
        ...mapState(useCallStore, ['spokenLanguage']),
    },
    methods : {
        ...mapActions(useCallStore, ["setSpokenLanguage"]),

        updateSpokenLanguage(e) {
            this.setSpokenLanguage(e.target.value);
            this.updateSpokenLanguageServer(e.target.value);
        },

        updateSpokenLanguageServer(lang_code, show_msg =true) {
            let self = this;
            let url = "event/participant";
            let postObj = {
                participant_id : this.participant.user_id,
                spoken_lang : lang_code
            }
            this.$axios.post(url, postObj).then(response => {
                if (response.status == 200) {
                    if (show_msg) {
                        toastr.success("Spoken language set successfully.",self.$t("success"),  {
                            closeButton: true,
                            tapToDismiss: false,
                            timeOut: 2000,
                            rtl: false,
                        })
                    }
                }
            }).catch(e => {
                console.log("spoken language set error", e);
            })

        }
    },
    mounted() {
        this.selected_spoken_language = this.spokenLanguage;
        this.updateSpokenLanguageServer(this.spokenLanguage, false);
    }
}
</script>